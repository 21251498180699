<template>
    <section class="services-pro">
        <!-- titre et menu -->
        <div class="position-relative">
            <!-- menu lateral -->
            <menu-widget></menu-widget>
            <!-- img -->
            <div class="container computer">
                <div class="title-effect position-relative">
                    <div class="">
                        <h1 class="text-center p-2 w-100">Mes Services Professionnel de santé</h1>
                        <label for="" class="text-muted">Ensemble, dynamisons la santé de notre territoire !</label>
                    </div>
                    <div id="#title-bg" class="background-text text-center">SERVICES</div>
                </div>
            </div>
            <div class="container mobile">
                <div class="position-relative">
                    <div class="">
                        <h2 class="text-center p-2 w-100" style="color:#23B2C2">Mes Services Professionnel de santé</h2>
                        <label for="" class="text-muted text-center">Ensemble, dynamisons la santé de notre territoire !</label>
                    </div>
                </div>
            </div>
        </div>

        <!-- contenu -->
        <div class="services-content" data-aos="fade-up" data-aos-duration="3000">
            <div class="container py-5">
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12 d-flex flex-column justify-content-center align-items-center"
                    v-for="item of items" :key="item.id"
                    >
                        <div class="circle-image mt-5">
                            <img class="" :src="require(`../../assets/images/import/cpts/${item.img}`)" alt="">
                        </div>
                        <h4 class="text-center" style="color:#fff">{{item.text}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import menuWidget from '../../components/page/accueil/menu-widget.vue'
import $ from 'jquery'
export default {
    name:"service-pro",
    components:{
        menuWidget
    },
    data(){
        return{
            items:[
                {
                    img:"img-1.png",
                    text:"Trouver un patient"
                },
                {
                    img:"img-2.png",
                    text:"Modifier mes informations"
                },
                {
                    img:"img-3.png",
                    text:"Télétransmission de document"
                },
                {
                    img:"img-3.png",
                    text:"Trouver une télésecrétaire"
                },
                {
                    img:"img-3.png",
                    text:"Annoncer un événement"
                },
                {
                    img:"img-3.png",
                    text:"Publier un article"
                },
                {
                    img:"img-3.png",
                    text:"Technologie Bluetooth"
                },
                {
                    img:"img-3.png",
                    text:"Annoncer un événement"
                },
                {
                    img:"img-3.png",
                    text:"Publier un article"
                }
            ]
        }
    },
    methods:{
        scrollText(){
            $(document).on('scroll', function() {
            $(".background-text").css("font-size", Math.max(0.07*window.scrollY, 10) + "rem");
            })
        }
    },
    mounted(){
        this.scrollText()
    }
}
</script>
<style lang="scss" scoped>
.services-content{
    background: url('../../assets/images/import/cpts/fond-2.png');
    background-size: cover;
    background-repeat: no-repeat;
}
// #title-bg {
//     color:red;
//     position: relative;
//     right: -10vw !important;
// }
</style>
